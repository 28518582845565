<template lang="">
  <div>
    <SubHeader :data="data" />
    <div class="container">
      <div class="container-text">
        <el-row :gutter="24">
          <el-col :md="24" :lg="8">
            <div class="contact-info">
              <img src="../assets/svg/icon-email.svg" alt="" />
              <div class="contact-info-title">
                <h1>{{ $t(`ContactUs['emailAddress']`) }}</h1>
                <p>mosapp.app@gmail.com</p>
              </div>
            </div>
            <div class="contact-info">
              <img src="../assets/svg/icon-phone.svg" alt="" />
              <div class="contact-info-title">
                <h1>{{ $t(`ContactUs['phoneNumber']`) }}</h1>
                <p>+855 87699188</p>
              </div>
            </div>
            <!-- <div class="contact-info">
              <img src="../assets/svg/icon-address.svg" alt="" />
              <div class="contact-info-title">
                <h1>{{ $t(`ContactUs['address']`) }}</h1>
                <p>111 SOMERSET ROAD #08-05, 111<br />
                  SOMERSET SINGAPORE (238164)</p>
              </div>
            </div> -->
          </el-col>
          <el-col :md="24" :lg="16">
            <div class="form-contacts">
              <div>
                <h1>{{ $t(`ContactUs['sendMessage']`) }}</h1>
                <p>{{ $t(`ContactUs['feelFreeToContact']`) }}</p>
              </div>
              <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-form-inline content-form">
                <el-row :gutter="24">
                  <el-col :span="12">
                    <el-form-item prop="userName">
                      <el-input v-model.number="ruleForm.firstName" :placeholder="$t(`ContactUs['userName']`)" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item prop="email">
                      <el-input v-model.number="ruleForm.email" :placeholder="$t(`ContactUs['email']`)" autocomplete="off"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item prop="mobileNumber">
                      <el-input v-model.number="ruleForm.mobileNumber" :placeholder="$t(`ContactUs['phoneNumber']`)" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item prop="subject">
                      <el-input v-model.number="ruleForm.subject" :placeholder="$t(`ContactUs['subject']`)" autocomplete="off"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-form-item prop="message">
                    <el-input class="textarea-style" type="textarea" v-model.number="ruleForm.message" :placeholder="$t(`ContactUs['message']`)"></el-input>
                  </el-form-item>
                </el-row>
                <el-form-item class="contact-btn">
                  <el-button type="primary" @click="submitForm('ruleForm')">{{ $t(`ContactUs['send']`) }}</el-button>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- <div class="contact-map">
      <h1>{{ $t(`ContactUs['findGoogleMaps']`) }}</h1>
      <ContactMap :maps="maps"/>
    </div> -->
  </div>
</template>
<script>
import SubHeader from '../components/SubHeader.vue'
// import ContactMap from '../components/ContactMap.vue'
import { postSendMessage } from '../api/sendCv'

export default {
  name: 'Contact Us',
  components: {
    SubHeader
    // ContactMap
  },
  data() {
    return {
      data: {
        label_name: 'contactUs',
        content_desc: 'contact_content',
        img: require('@/assets/image/sub-header/contactUs.png')
      },
      ruleForm: {
        email: '',
        userName: '',
        subject: '',
        message: '',
        mobileNumber: ''
      },
      rules: {
        email: [
          { required: true, message: this.$t('ContactUs.input_email'), trigger: 'change' }
        ],
        userName: [
          { required: true, message: this.$t('ContactUs.input_userName'), trigger: 'blur' }
        ],
        subject: [
          { required: true, message: this.$t('ContactUs.input_subject'), trigger: 'change' }
        ],
        mobileNumber: [
          { required: true, message: this.$t('ContactUs.input_phoneNumber'), trigger: 'change' }
        ],
        message: [
          { required: true, message: this.$t('ContactUs.input_message'), trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          postSendMessage(this.form)
            .then(async response => {
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.container-text {
  h1 {
    font-size: 20px;
    font-weight: 500;
  }
  p {
    font-size: 17px;
  }
  padding: 40px 0 40px;
  .form-contacts {
    background-color: #FAFAFA;
    padding: 20px;
    border-radius: 15px;
    h1 {
        font-size: 26px;
      }
    p {
      color: #595959;;
    }
  }
  form.el-form.demo-form-inline.content-form {
    margin-top: 20px;
    .el-form-item.contact-btn {
      justify-content: flex-end;
      display: flex;
    }
    ::v-deep .el-textarea__inner {
      height: 320px;
    }
  }
  .contact-info {
    display: flex;
    -moz-column-gap: 20px;
    column-gap: 20px;
    margin-bottom: 55px;
    align-items: center;
    img {
      width: 50px;
    }
    .contact-info-title {
      h1 {
        font-size: 26px;
      }
      p {
        font-size: 17px;
        color: #595959;
      }
    }
  }
}
.contact-map {
    text-align: center;
    padding: 0px 0 30px 0;
    h1 {
      font-size: 30px;
      margin: 40px 0px 50px 0px;
    }
  }
</style>
